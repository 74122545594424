import React from "react";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";

import {flexAlignCenter, mb} from "@pg-design/helpers-css";
import {useTranslation} from "@pg-design/i18n";
import {Text} from "@pg-design/text-module";

import {useAnimatedHomepageHeader} from "../hooks/use_animated_homepage_header";

export const HomepageHeaderContent = () => {
    const slides = ["Od marzenia do wprowadzenia", "Od kluczenia do własnych kluczy", "Przez proces wyboru mieszkania"];
    const translatedSlides = useTranslation(slides);
    const headline = useAnimatedHomepageHeader(translatedSlides ? translatedSlides : slides);

    return (
        <div css={homepageHeaderContent}>
            <Headline as="h1" align="center" fontFamily="secondary">
                {headline}
                <div css={subHeadline}>Przeprowadzimy Cię</div>
            </Headline>
        </div>
    );
};

const homepageHeaderContent = css`
    ${flexAlignCenter};
    flex-direction: column;
    position: relative;
`;

const Headline = styled(Text)`
    color: #fff;
    font-size: 3.6rem;
    font-weight: 700;
    line-height: 4.32rem;
    z-index: 1;
    ${mb(8)};

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
        font-size: 7.2rem;
        line-height: 8.64rem;
        ${mb(4)}
    }
`;

const subHeadline = (theme: Theme) => css`
    font-size: 2.8rem;
    font-weight: 400;
    line-height: 3.36rem;

    @media (min-width: ${theme.breakpoints.md}) {
        font-size: 5.4rem;
        line-height: 6.48rem;
    }
`;
