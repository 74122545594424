export enum InquiryButtonTypeName {
    ASK = "ZAPYTAJ",
    REVEAL = "ODKRYJ",
    OFFER = "ZAPYTAJ O OFERTĘ",
    PRICE = "ZAPYTAJ O CENĘ",
    PRICES = "ZAPYTAJ O CENY",
    REVEAL_PRICE = "ODKRYJ CENĘ",
    REVEAL_PRICES = "ODKRYJ CENY",
    RESERVATION = "Rezerwacja",
    DISCOUNT = "ZAPYTAJ O RABAT",
    ARRANGE_MEETING = "UMÓW SIĘ NA SPOTKANIE",
    INVESTMENT = "ZAPYTAJ DEWELOPERA O OFERTĘ",
    PROMOTIONS = "KONTAKT Z DEWELOPEREM",
    VENDOR = "ZAPYTAJ DEWELOPERA"
}
